import React from "react";

//import components
import 'animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Projects } from "../components/Projects/Projects";


const WhatOffer = () => {
  
  return (
    <div>
        <Projects />
    </div>
  )
}

export default WhatOffer;