import React from "react"
import { project } from "../data/dummydata"
// import CountUp from "react-countup"

//yarn or npm add react-countup

export const Counter = () => {
  return (
    <>
      
    </>
  )
}


export default Counter;