import React from 'react';
import ReactDOM from 'react-dom/client';
// import {  Routes, Route } from 'react-router-dom';
import './index.css';
import  App  from './App.js';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import CursorProvider from './context/CursorContext';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CursorProvider>
  <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
</React.StrictMode>
</CursorProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
