import React from "react";

//import components
import 'animate.css';
import { GetQuote } from "../components/GetQuote";
import '../getQuote.css'



const Test = () => {
  
  return (
    <div>
        <GetQuote />
    </div>
  )
}

export default Test;