// import { Settings, CropRotate, ViewInAr, PieChart, Code, BarChart, CloudOutlined, FavoriteBorder, Public, PersonOutlined, AddLocationAltOutlined, PhoneIphone, EmailOutlined, Facebook, Twitter, Instagram, YouTube } from "@mui/icons-material"
import React from "react"
// import ScaleIcon from '@mui/icons-material/Scale';
// import TableRowsIcon from '@mui/icons-material/TableRows';
// import PublicIcon from '@mui/icons-material/Public';
// import AcUnitIcon from '@mui/icons-material/AcUnit';
// import LocalShippingIcon from '@mui/icons-material/LocalShipping';
export const navlink = [
  {
    url: "/",
    text: "Home",
  },
  {
    url: "/about",
    text: "About",
  },
  {
    url: "/services",
    text: "Services",
  },
  {
    url: "/portfolio",
    text: "Portfolio",
  },
  {
    url: "/testimonials",
    text: "Testimonials",
  },
  {
    url: "/blog",
    text: "Blog",
  },
  {
    url: "/contact",
    text: "Contact",
  },
]
export const home = [
  {
    text: "HELLO I'M",
    name: "ALAMIN MUSA",
    post: "WEB DESIGNER",
    design: "UI / UX DESIGNER",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
]
export const about = [
  {
    desc: "Our experienced truckload experts leverage our size, scale, and network of over 50,000 carriers to bring you access to unmatched capacity at competitive rates. Regardless of season or spikes, we have the capacity to get your freight where it needs to go.",
    desc1: " Save time and focus on strategic activities as our full truckload experts handle every step of the sourcing, tendering, and billing process. Quote, book, ship, track, and manage invoices, all in one place with our web portal for shippers, providing you instant access to our full network of truckload capacity 24/7, 365 days a year. ",
    cover: "../images/man1.png",
  },
]
export const aboutLTL = [
  {
    desc: "Our diverse Carrier Portfolio offers National, Regional, and Micro-Regional partners who are all service leaders in their geographical footprint. Our innovative TMS comes with a module explicitly built for LTL shippers that allows for efficient, cost-effective quoting, booking, tracking, tracing, and auditing of all of your LTL and Volume LTL freight.",
    desc1: "Regardless of your load’s destination, rest assured we have the expertise and services to get it there.",
    cover: "../images/man2.png",
  },
]

export const aboutRail = [
  {
    desc: "No-stress shipping, on-time delivery and low rates guaranteed with rail freight from Direct Drive Logistics. Our rail freight brokers work with a wide network of insured owner operators, allowing us to broker the best deals on domestic rail freight.",
    desc1: "We handle every aspect of rail shipping, from container to train to final destination. Our rail freight brokers map the most cost-efficient route to guarantee your shipment arrives safely and on-time. ",
    cover: "../images/man3.png",
  },
] 

export const aboutAir = [
  {
    desc: "Vertex Solutions is your dependable source for air freight broker services with the flexibility to meet your changing needs. With our comprehensive range of time-definite, guaranteed and cost-saving options, Vertex Solutions can meet your requirements with reliability and professionalism. ",
    desc1: "Select from transit times ranging from next flight out (NFO), overnight, or economy (general freight) depending on your individual requirements. We'll assess your shipment in full and find the best air freight rates to match.",
    cover: "../images/man4.png",
  },
]
export const faq = [
  {
    desc: ""    
  },
]

export const aboutOcean = [
  {
    desc: "Container ships with capacities from 3,000 to 24,000 TEU connect the most important hubs of world trade and continents on earth. The routes on the seven seas are precisely calculated, the schedule is precisely tailored to the day. Container ships help to keep global economic flows in tact. ",
    desc1: "According to the International Chamber of Shipping (ICS), around 90 percent of cross-border trade in goods is transported by sea – based on the weight of the goods transported.",
    cover: "../images/man5.png",
  },
]
export const aboutWarehouse = [
  {
    desc: "Container ships with capacities from 3,000 to 24,000 TEU connect the most important hubs of world trade and continents on earth. The routes on the seven seas are precisely calculated, the schedule is precisely tailored to the day. Container ships help to keep global economic flows in tact. ",
    desc1: "According to the International Chamber of Shipping (ICS), around 90 percent of cross-border trade in goods is transported by sea – based on the weight of the goods transported.",
    cover: "../images/man6.png",
  },
]

export const aboutVolume = [
  {
    desc: "Partial truckload is a type of freight designed specifically for sizable shipments that generally do not require the use of a full truckload trailer. A partial truckload, as it were, helps cut down on costs for everyone involved in the transaction.",
    desc1: "Often, a partial load will fall between LTL freight and full truckload shipping logistics. These partial truckload shipments, generally speaking, weigh more than 5,000 pounds total or include six or more pallets of equipment or items for transit.",
    cover: "../images/man7.png",
  },
]

export const aboutCarrier = [
  {
    desc: "We aim to create long-term partnerships with our carriers to provide excellent rates and outstanding service to our customers. All trucking companies in our network are background checked to ensure that our standards remain high and that our clients can always explore the best options for their shipments.",
    cover: "../images/man7.png",
  },
]



export const services = [
  {
    id: 1,
    // icon: <LocalShippingIcon />,
    title: "Dry van",
    desc: "We have the capacity to handle all your dry van freight. Tap into one of the largest 3PL networks in North America.",
  },
  {
    id: 2,
    // icon: <AcUnitIcon />,
    title: "Temperature-controlled freight",
    desc: " Whether it’s keeping your freight cool or within a specific temperature range, we have the capacity to maintain the quality you expect in your product.",
  },
  {
    id: 3,
    // icon: <PublicIcon />,
    title: "Cross-border",
    desc: " Whether you’re shipping into or out of Mexico or Canada, we have the carrier relationships, technology, and experience to move your shipments with ease.",
  },
  {
    id: 4,
    // icon: <TableRowsIcon />,
    title: "Flatbed	",
    desc: "Our experts and extensive capacity have all your unique cargo covered with various types of flatbed trailer options. ",
  },
  {
    id: 5,
    // icon: <ScaleIcon />,
    title: "Over-dimensional/ Heavy Haul",
    desc: " Is your freight oversized or too big to fit on a standard truck? Our network of contract carriers and private fleets gives you peace of mind knowing we can handle your freight even if it exceeds one or more of the standard legal size criteria.",
  },
  // {
  //   id: 6,
  //   icon: <BarChart />,
  //   title: "Awesome Support",
  //   desc: "Lorem Ipsum simply text of the printing and type setting industry when an unknown printing simply",
  // },
]
export const project = [
  {
    id: 1,
    // icon: <CloudOutlined />,
    num: "89",
    title: "HAPPY CLIENTS",
  },
  {
    id: 2,
    // icon: <FavoriteBorder />,
    num: "231",
    title: "PROJECTS COMPLEATED",
  },
  {
    id: 3,
    // icon: <Public />,
    num: "108",
    title: "FILES DOWNLOADED",
  },
  {
    id: 4,
    // icon: <PersonOutlined />,
    num: "1446",
    title: "LIENS OF CODE",
  },
]
export const portfolio = [
  {
    id: 1,
    cover: "../images/port/port1.jpg",
    name: "Our extensive network of over 50,000 carriers undergo rigorous carrier qualification and are hand selected through a strict compliance process /n/ for proven ability to deliver the highest-quality and best-value service. Our network includes OTR fleets of all sizes, including owner-operators, as well as dedicated capacity and private fleets, which means we offer capacity you can’t find elsewhere.",
    category: "marketing",
    title: "Echo’s Extensive Carrier Network Brings You Access to Capacity",
  },
  {
    id: 2,
    cover: "../images/port/port2.jpg",
    name: "Brand",
    category: "design",
    title: "Brex Logo",
  },
  {
    id: 3,
    cover: "../images/port/port3.jpg",
    name: "Brand",
    category: "development",
    title: "Brex Logo",
  },
  {
    id: 4,
    cover: "../images/port/port4.jpg",
    name: "Brand",
    category: "marketing",
    title: "Brex Logo",
  },
  {
    id: 5,
    cover: "../images/port/port5.jpg",
    name: "Brand",
    category: "design",
    title: "Brex Logo",
  },
  {
    id: 6,
    cover: "../images/port/port6.jpg",
    name: "Brand",
    category: "development",
    title: "Brex Logo",
  },
]
export const testimonials = [
  {
    id: 1,
    text: "I had an issue with my current 3PL which is why I was exploring Vertex Solutions but Manish helped fix the main problem with a solution that involved a change of a setting that I think helps solve the problem. If other issues persist with our current 3PL I'm definitely coming to Vertex Solutions first.",
    image: "./images/testimonials/team-1.png",
    name: "Chris Hamm",
    post: "Front End Developer",
  },
  {
    id: 2,
    text: "Excellent company , I highly recommend. If you are looking for a cheap and fast 3PL, Vertex Solutions will be the best option in the US",
    image: "./images/testimonials/team-2.png",
    name: "Alex Garcia",
    post: "Back End Developer",
  },
  {
    id: 3,
    text: "Vertex Solutions has been an awesome company to work with. Sales representative has been an amazing source for all my shipping needs. They take customer service very seriously and is great to deal with. Looking forward to doing business in the future !",
    image: "./images/testimonials/team-3.png",
    name: "cailin guerins",
    post: "React Developer",
  },
]
export const blog = [
  {
    id: 1,
    title: "Vertex Solutions's Extensive Carrier Network Brings You Access to Capacity",
    date: "Jun 27, 2022",
    author: "Dorian Gray",
    desc: "Our extensive network of over 50,000 carriers undergo rigorous carrier qualification and are hand selected through a strict compliance process for proven ability to deliver the highest-quality and best-value service.Our network includes OTR fleets of all sizes, including owner-operators, as well as dedicated capacity and private fleets, which means we offer capacity you can’t find elsewhere.",
    cover: "./images/blog/b1.png",
  },
  { 
    id: 2,
    title: "Vertex Solutions's Best-in-Class Technology Allows Shippers to Quote and Book Shipments Faster Than Ever Before",
    date: "Jun 27, 2022",
    author: "Dorian Gray",
    desc: "Our proprietary technology, EchoShip, is an innovative self-service web portal that that enables you to quote, book, ship, track, and manage invoices, all in one place. EchoShip’s digital freight network offers access to our competitive carrier marketplace to help you find the right carrier for your business needs. Additionally, EchoShip offers access to the reporting and analytics you need to make informed decisions and uncover continuous improvement opportunities.",
    cover: "./images/blog/b3.png",
  },
  {
    id: 3,
    title: "Vertex Solutions Has The Right Truckload Solution to Fit Your Needs",
    date: "Jun 27, 2022",
    author: "Dorian Gray",
    desc: "We have the right solution to move whatever items you need to ship, whether your shipment is hazmat, dry van, reefer, flatbed, or has multiple stops. In addition, we ship cross-border to Canada and Mexico, making us one of the largest and most well-connected 3PLs in North America.",
    cover: "./images/blog/b2.png",
  },
]
export const contact = [
  {
    // icon: <AddLocationAltOutlined />,
    text1: "2651 Main Street, Suit 124",
    text2: "Seattle, WA, 98101",
  },
  {
    // icon: <PhoneIphone />,
    text1: "0123456789",
    text2: "0345627891",
  },
  {
    // icon: <EmailOutlined />,
    text1: "hello@thetheme.io",
    text2: "inf0@brex-theme.io",
  },
]
// export const social = [
//   {
//     icon: <Facebook />,
//   },
//   {
//     icon: <Twitter />,
//   },
//   {
//     icon: <Instagram />,
//   },
//   {
//     icon: <YouTube />,
//   },
// ]
