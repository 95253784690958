import React from "react";
import com1 from "../assets/img/com-1.png";
import com2 from "../assets/img/com-2.png";
import com3 from "../assets/img/com-3.png";
import com4 from "../assets/img/com-4.png";
import com5 from "../assets/img/com-5.png";
const Companies = () => {
  return (
    <></>
  );
};

export default Companies;
